"use client";

import { PageError } from "@/components/error/page-error";
import { Button } from "@/components/base/button";

export default function GlobalError({ reset }: { error: Error; reset: () => void }) {
  return (
    <>
      <header></header>

      <main>
        <PageError errorCode="500" heading="Noe gikk galt" />

        <Button
          buttonType="secondary"
          onClick={
            // Attempt to recover by trying to re-render the segment
            () => reset()
          }
        >
          Last siden inn igjen
        </Button>
      </main>

      <footer></footer>
    </>
  );
}
